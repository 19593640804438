import { Collapse, Divider, List, ListItemButton } from "@mui/material";
import FooterStartButton from "components/FooterStartButton";
import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "styles/Colors";

interface PricingPhilosophyProps {
  isMobile?: boolean;
  language?: string;
}

const PricingPhilosophy = ({ isMobile, language }: PricingPhilosophyProps) => {
  const PricingPolicy = [
    {
      name: "free",
      title:
        language === "ko"
          ? "프로 플랜이 출시되어도 무료로 이용할 수 있는 이유?"
          : `Why Will the ‘Action’ Area Remain Free Even After the Pro Plan Launches?`,
      content:
        language === "ko" ? (
          <>
            <span>
              {`데일리 플래닝을 할 수 있는 ‘실행’ 영역은 영원히 무료로 제공할
                  예정입니다. 이유는 두 가지예요.`}
            </span>
            <span>
              {`첫째, SLASH의 목표는 ‘모두가 효과적인 프로가 되는 것’이에요.
                  과거에 혁신가들이 누구나 컴퓨터와 자동차를 사용할 수 있게 했듯이,
                  SLASH는 누구나 자신의 비전을 실현할 능력을 가지도록 돕고자 합니다.
                  이를 위해 ‘탁월성에 대한 접근성’을 보장하고자 핵심 기능은 무료로
                  제공됩니다.`}
            </span>
            <span>
              {`둘째, 오늘 하루를 잘 보내는 것이 탁월함의 시작이기 때문입니다. 큰
                  목표도 하루하루의 실행이 뒷받침되지 않으면 의미가 없죠. SLASH는
                  Bottom-up 접근을 통해 오늘부터 생산성을 높이는 방법을 제안합니다.
                  먼저 무료로 하루 시간을 관리하는 법을 익히고, 이후 주간 계획, 월간
                  목표, 회고 등 프로 기능을 통해 생산성을 높여가보세요.`}
            </span>
          </>
        ) : (
          <>
            <span>
              {`The “Action” area for daily planning will always remain free. Here
                  are the two reasons why.`}
            </span>
            <span>
              {`First, SLASH’s goal is to enable everyone to become an effective
                  professional. Just as past innovators made computers and cars
                  accessible to all, SLASH aims to empower everyone to achieve their
                  vision. To ensure this “accessibility to excellence,” SLASH’s core
                  features will always be free.`}
            </span>
            <span>
              {`Second, excellence begins with managing each day well. Even the
                  biggest goals hold little meaning without daily action. SLASH
                  takes a bottom-up approach, focusing on improving productivity
                  starting today. Begin by mastering daily time management for free,
                  then gradually enhance productivity with Pro features like weekly
                  planning, monthly goals, and reflection.`}
            </span>
          </>
        ),
    },
    {
      name: "pro",
      title:
        language === "ko"
          ? "출시 예정인 유료 플랜이 월 $10인 이유?"
          : `Why the Upcoming Paid Plan is Priced at $10 per Month`,
      content:
        language === "ko" ? (
          <>
            <span>
              {`‘가격 설정(Pricing)’은 서비스의 성장 속도에 가장 큰 변화를 줄 수 있는 요인이라고 합니다. 하지만 저희의 가격 설정은 그렇게 정교하게 하진 않았어요.`}
            </span>
            <span>
              {`다른 생산성 툴의 가격 정책은 큰 참고가 되지는 않았는데요, 그 이유는 ‘개인 생산성의 모든 문제를 기본부터 풀어내는’ SLASH의 접근법상 어떤 하나의 전통적인 툴과 비교하는 것이 어렵더라고요(협업 중심인 생산성 툴, 타임트래킹만되는 생산성 툴 등)`}
            </span>
            <span>
              {`결국 사용자 입장에서는 “‘내돈내산’으로 구독 서비스 하나를 추가할만큼 이 서비스가 가치가 있는지”가 중요하지 않을까요? 그래서 저희는 넷플릭스의 요금제 수준인 $10로 책정했어요. 지금은 넷플릭스 가격이 더 인상됐지만 오랫동안 $9.99달러의, 상당히 경쟁력 있는 요금이었죠.`}
            </span>
            <span>
              {`프로들 입장에서는 콘텐츠 소비자로서의 자신을 위해 $9.99를 내는만큼, 콘텐츠 생산자로서의 자신을 위해 $10를 내는 것은 충분히 합리적이라고 생각했어요.`}
            </span>
          </>
        ) : (
          <>
            <span>
              {`Pricing is often seen as one of the most influential factors in driving a service’s growth. However, our pricing approach hasn’t been overly calculated.`}
            </span>
            <span>
              {`We found it difficult to reference other productivity tools’ pricing because SLASH’s approach — to solve all aspects of personal productivity from the ground up — makes it hard to directly compare with traditional tools focused solely on collaboration or time tracking.`}
            </span>
            <span>
              {`Ultimately, we think the main question for users is, “Is this service worth adding as a paid subscription?” With that in mind, we set the price at $10, similar to Netflix’s classic rate of $9.99, which was long considered a very competitive price. Though Netflix has since raised its fees, $9.99 remained an attractive price for many years.`}
            </span>
            <span>
              {`From a professional perspective, if they’re willing to spend $9.99 as consumers of content, it seems reasonable to spend $10 as creators of their own productivity.`}
            </span>
          </>
        ),
    },
    {
      name: "believer",
      title:
        language === "ko"
          ? `빌리버 플랜이 ‘5년’의 기간을 통으로 구매하도록 설계된 이유? `
          : `Why the Believer Plan is Designed as a 5-Year Commitment`,
      content:
        language === "ko" ? (
          <>
            <span>
              {`“SLASH를 열심히 써서 생산성을 올려야겠다!”라고 결심하시고 또 그렇게 잘 하고 계신분들이 많습니다. SLASH가 결심의 대상이 되기도 한다니, 이때 연상된 건 피트니스센터였어요. 피트니스센터를 다니시는 분들은 ‘이성적’인 판단으로 1년치 등록비를 냅니다. 그리고 가기 귀찮다는 ‘감성적’인 저항을 이겨내고 본전을 뽑기 위해 피트니스센터에 열심히 가죠.`}
            </span>
            <span>
              {`피트니스센터에 1년치 등록비를 냄으로써 우리는 ‘운동하는 나’라는 자아를 구매합니다. SLASH에 5년치 등록비를 냄으로써 프로가 ‘성장하는 나’라는 자아를 구매하게끔 하고자 함이 ‘5년 플랜’의 배경입니다. 5년 뒤 SLASH와 함께 프로로서 더 성장할 나를 그려보세요.`}
            </span>
          </>
        ) : (
          <>
            <span>
              {`Many of our users are determined to “Use SLASH to boost my productivity!” and are actively working towards that goal. SLASH has become a part of their commitment, reminding us of a fitness center. When people sign up for a full year at a fitness center, they’re making a rational decision to invest in “becoming the person who works out.” By overcoming the emotional resistance—like the temptation to skip workouts—they make the most of their commitment.`}
            </span>
            <span>
              {`Similarly, by investing in a 5-year SLASH membership, we aim to help you embrace “becoming the person who grows.” Imagine yourself in 5 years, having grown alongside SLASH into a more effective professional.`}
            </span>
          </>
        ),
    },
  ];

  const [openPricePolicy, setOpenPricePolicy] = useState([
    { name: "free", open: false },
    { name: "pro", open: false },
    { name: "believer", open: false },
  ]);

  const handleClickPricePolicy = (string: string) => {
    const index = openPricePolicy.findIndex((p) => p.name === string);
    openPricePolicy[index].open = !openPricePolicy[index]?.open;
    setOpenPricePolicy([...openPricePolicy]);
  };

  return (
    <Container>
      <PricingPolicyTitle>
        {language === "ko" ? "가격 철학" : "Pricing Philosophy"}
      </PricingPolicyTitle>
      <List sx={{ width: "754px" }}>
        {PricingPolicy.map((policy, idx) => (
          <>
            <ListItemButton
              onClick={() => handleClickPricePolicy(policy.name)}
              sx={{
                padding: "0px",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "36px",
                letterSpacing: "-0.25px",
                "&:hover": {
                  color: COLORS.brand1,
                  backgroundColor: COLORS.white,
                },
              }}
            >
              <div>{policy.title}</div>
            </ListItemButton>
            <Collapse
              in={openPricePolicy.find((p) => p.name === policy.name)?.open}
              timeout="auto"
              unmountOnExit
              sx={{ marginTop: "12px" }}
            >
              <CollapseContent>{policy.content}</CollapseContent>
            </Collapse>
            <Divider sx={{ margin: "20px 0px" }} />
          </>
        ))}
      </List>
    </Container>
  );
};

export default PricingPhilosophy;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  box-sizing: border-box;
`;

const PricingPolicyTitle = styled.div`
  font-size: 40px;
  font-weight: 700;
  line-height: 150%; /* 60px */
  letter-spacing: -0.25px;
`;

const CollapseContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.25px;

  > span {
    :not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;
