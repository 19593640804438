import React, { SVGProps } from "react";

export const Check = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6526 10.502C23.0932 10.9721 23.0694 11.7104 22.5994 12.1511L13.266 20.9011C12.8173 21.3218 12.1189 21.3218 11.6702 20.9011L7.0035 16.5261C6.53344 16.0854 6.50962 15.3471 6.95031 14.877C7.39099 14.407 8.1293 14.3832 8.59937 14.8238L12.4681 18.4508L21.0035 10.4488C21.4736 10.0082 22.2119 10.032 22.6526 10.502Z"
        fill={props.fill || "#1F2023"}
      />
    </svg>
  );
};
