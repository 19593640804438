import React, { Suspense, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import GTD from "pages/GTD";
import { Retrospect } from "pages/Retrospect";
import { Outlet, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import Pricing from "pages/Pricing";
import styled from "styled-components";
import Header from "components/Header";
import Footer from "components/Footer";
import Believer from "pages/Believer";

const MainLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

interface AppLayoutProps {
  isMobile?: boolean;
  language?: string;
  onChangeLanguage?: (language: string) => void;
}

const AppLayout = ({
  isMobile,
  language,
  onChangeLanguage,
}: AppLayoutProps) => {
  const [hideFooter, setHideFooter] = useState(false);
  const handleHideFooter = () => {
    setHideFooter(true);
  };

  useEffect(() => {
    if (hideFooter) {
      setTimeout(() => {
        setHideFooter(false);
      }, 100);
    }
  }, [hideFooter]);

  return (
    <MainLayout>
      <Header
        isMobile={isMobile}
        language={language}
        onHideFooter={handleHideFooter}
      />
      <Suspense fallback={<div />}>
        <Outlet />
      </Suspense>
      {!hideFooter && (
        <Footer
          isMobile={isMobile}
          language={language}
          onChangeLanguage={onChangeLanguage}
        />
      )}
    </MainLayout>
  );
};

const App = () => {
  const isBigDesktop: boolean = useMediaQuery({
    query: "(min-width:1600px)",
  });
  const isDesktop: boolean = useMediaQuery({
    query: "(min-width:1024px)",
  });
  const isTablet: boolean = useMediaQuery({
    query: "(min-width:992px) and (max-width:1023px)",
  });
  const isSmallTablet: boolean = useMediaQuery({
    query: "(min-width:768px) and (max-width:991px)",
  });
  const isMobile: boolean = useMediaQuery({
    query: "(max-width:767px)",
  });

  const location = useLocation();
  const [init, setInit] = useState(false);
  const [language, setLanguage] = useState(
    navigator.language.split("-")[0] === "ko" ? "ko" : "en"
  );

  const handleChangeLanguage = (language: string) => {
    setLanguage(language);
  };

  const blue = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID!;
  //localhost에서는 GA 작동 안하게
  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID!);
      setInit(true);
    }
  }, []);

  //location이 바뀔 때마다 pageview 전송
  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send("pageview");
  }, [init, location]);

  //개발용
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID!);
    ReactGA.set({ page: location.pathname });
    ReactGA.send("pageview");
  }, [location]);

  const LazyGTD = React.lazy(() => import("pages/GTD"));

  return (
    <>
      <Routes>
        <Route
          element={
            <AppLayout
              isMobile={isMobile}
              language={language}
              onChangeLanguage={handleChangeLanguage}
            />
          }
        >
          <Route
            path="/"
            element={
              <LazyGTD
                isBigDesktop={isBigDesktop}
                isDesktop={isDesktop}
                isTablet={isTablet}
                isSmallTablet={isSmallTablet}
                isMobile={isMobile}
                language={language}
                // isSmallMobile={isSmallMobile}
              />
            }
          />
          <Route
            path="/pricing"
            element={
              <Pricing
                isBigDesktop={isBigDesktop}
                isDesktop={isDesktop}
                isTablet={isTablet}
                isSmallTablet={isSmallTablet}
                isMobile={isMobile}
                language={language}
              />
            }
          />
          <Route
            path="/believer"
            element={
              <Believer
                isBigDesktop={isBigDesktop}
                isDesktop={isDesktop}
                isTablet={isTablet}
                isSmallTablet={isSmallTablet}
                isMobile={isMobile}
                language={language}
              />
            }
          />
          <Route
            path="/retro"
            element={
              <Retrospect
                isMobile={isMobile}
                isTablet={isTablet}
                isDesktop={isDesktop}
              />
            }
          />
          <Route
            path="*"
            element={
              <LazyGTD
                isMobile={isMobile}
                isTablet={isTablet}
                isDesktop={isDesktop}
                isBigDesktop={isBigDesktop}
                isSmallTablet={isSmallTablet}
                language={language}
                // isSmallMobile={isSmallMobile}
              />
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default App;
