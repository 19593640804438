import FooterStartButton from "components/FooterStartButton";
import PricingPhilosophy from "components/PricingPhilosophy";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import styled from "styled-components";
import { COLORS } from "styles/Colors";
import { useLocation } from "react-router-dom";

interface BelieverProps {
  isBigDesktop?: boolean;
  isDesktop?: boolean;
  isTablet?: boolean;
  isSmallTablet?: boolean;
  isMobile?: boolean;
  language?: string;
}

const Believer = ({
  isBigDesktop,
  isDesktop,
  isTablet,
  isSmallTablet,
  isMobile,
  language,
}: BelieverProps) => {
  const location = useLocation();
  const [render, setRender] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setRender(true);
  }, [location]);

  const benefits = [
    {
      title: "Invest in your Growth",
      content:
        language === "ko"
          ? [
              "하루 150원으로 5년간, 자신을 성장시키기 위한 강력한 도구에 투자하세요",
            ]
          : [
              `Invest in a powerful tool to grow yourself over 5 years for just 10 cent per day`,
            ],
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="160"
          height="160"
          viewBox="0 0 160 160"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0007 26.6665C23.6826 26.6665 26.6673 29.6513 26.6673 33.3332V85.7677L41.4221 73.4721C41.4432 73.4545 41.4644 73.437 41.4858 73.4197L41.6073 73.3184C43.7909 71.4986 45.6864 69.919 47.3543 68.7146C49.1198 67.4397 50.9977 66.307 53.2244 65.6119C57.6999 64.2149 62.5193 64.4366 66.8456 66.2313C69.002 67.1259 70.7711 68.4248 72.4166 69.8567C73.9711 71.2094 75.7193 72.9578 77.7352 74.9738L77.9295 75.1681C80.1739 77.4125 81.6186 78.8518 82.802 79.8797C83.9337 80.8627 84.4677 81.1437 84.7569 81.2615C86.2194 81.8572 87.8575 81.9121 89.3731 81.4093C89.6749 81.3092 90.2315 81.0617 91.4328 80.1514C92.6888 79.1999 94.2364 77.8516 96.6393 75.7491L135.61 41.6493C138.381 39.2248 142.593 39.5056 145.018 42.2765C147.442 45.0474 147.161 49.2591 144.39 51.6837L105.283 85.9029C103.076 87.8337 101.167 89.5042 99.4848 90.7789C97.7133 92.1211 95.8249 93.3169 93.5709 94.0646C89.0576 95.5617 84.1467 95.4101 79.7266 93.6095C77.5302 92.7147 75.7294 91.3973 74.0585 89.9459C72.4786 88.5736 70.7022 86.7971 68.6548 84.7496L68.4584 84.5531C66.2481 82.3429 64.8274 80.9274 63.664 79.9151C62.5529 78.9482 62.0264 78.6672 61.7365 78.547C60.2915 77.9475 58.6863 77.8748 57.1975 78.3396C56.8998 78.4325 56.3514 78.6639 55.16 79.5243C53.9126 80.425 52.3735 81.7027 49.9791 83.698C49.958 83.7156 49.9367 83.7331 49.9153 83.7505L26.6673 103.124V112C26.6673 115.844 26.6725 118.325 26.8268 120.213C26.9748 122.023 27.2258 122.694 27.394 123.024C28.0361 124.284 29.0573 125.305 30.3059 125.941C30.634 126.108 31.3043 126.359 33.1131 126.507C34.9993 126.661 37.4771 126.667 41.3134 126.667H140.002C143.684 126.667 146.669 129.651 146.669 133.333C146.669 137.015 143.684 140 140.002 140H41.0566C37.5493 140 34.5217 140 32.0271 139.796C29.3944 139.581 26.774 139.106 24.2527 137.821C20.4836 135.901 17.4284 132.834 15.5139 129.077C14.2288 126.555 13.7532 123.934 13.5378 121.299C13.3338 118.802 13.3339 115.771 13.334 112.257L13.334 33.3332C13.334 29.6513 16.3188 26.6665 20.0007 26.6665Z"
            fill="url(#paint0_linear_4068_12048)"
            fillOpacity="0.45"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4068_12048"
              x1="147"
              y1="140"
              x2="13"
              y2="27"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#E7EAF4" />
              <stop offset="1" stopColor="#2D6ADF" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
    {
      title: "Early Access",
      content:
        language === "ko"
          ? ["새롭게 개발된 기능들을 가장 먼저 경험해보세요"]
          : [`Be the first to experience newly developed features`],
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="160"
          height="160"
          viewBox="0 0 160 160"
          fill="none"
        >
          <path
            d="M88.8325 71.1684L36.2712 123.73M63.1763 50.4041L40.0925 50.6048C38.1373 50.6197 36.2712 51.4077 34.8959 52.7831L14.4215 73.2574C12.3696 75.3168 13.3658 78.8332 16.1983 79.5023L41.9437 85.5837L51.0558 64.0003M92.2523 23.9896C109.433 35.7063 124.295 50.5676 136.011 67.7485M74.4097 118.057L80.4911 143.802C81.1602 146.635 84.6766 147.631 86.736 145.579L107.21 125.105C108.586 123.73 109.374 121.864 109.389 119.908L109.589 96.8245L92.0337 111.418M51.0558 64.0003C53.1388 60.9409 55.5135 58.0669 58.1581 55.4223L81.8739 31.714C88.0147 25.5657 95.8208 21.3504 104.333 19.581L133.238 13.574C141.104 11.9385 148.062 18.8971 146.427 26.7627L140.42 55.6676C138.65 64.18 134.435 71.9861 128.287 78.127L104.579 101.835C100.818 105.595 96.5948 108.811 92.0337 111.418M51.0558 64.0003C46.0126 71.4076 42.6791 79.9022 41.3638 88.8622L41.282 89.4049C40.1074 97.4861 42.8135 105.642 48.5826 111.418C54.3517 117.187 62.5147 119.893 70.5959 118.711L71.1386 118.63C78.5377 117.548 85.6195 115.085 92.0337 111.418"
            stroke="url(#paint0_linear_4068_12055)"
            strokeOpacity="0.45"
            strokeWidth="13.3333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4068_12055"
              x1="13"
              y1="13"
              x2="147"
              y2="157"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2D6ADF" />
              <stop offset="1" stopColor="#E7EAF4" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
    {
      title: "Contiributions",
      content:
        language === "ko"
          ? [
              "최고의 도구를 함께 만들어가요! SLASH의 로드맵을 미리 확인하고, 기능 제안과 투표를 통해 발전에 기여할 수 있어요",
            ]
          : [
              `Help build the best tool! Get a sneak peek at SLASH’s roadmap, make feature suggestions, and vote to shape its development`,
            ],
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="160"
          height="160"
          viewBox="0 0 160 160"
          fill="none"
        >
          <path
            d="M50.7683 140.67H32.7878C29.6086 140.67 26.5597 139.407 24.3117 137.159C22.0637 134.911 20.8008 131.862 20.8008 128.683V86.7288C20.8008 83.5497 22.0637 80.5007 24.3117 78.2527C26.5597 76.0047 29.6086 74.7418 32.7878 74.7418H50.7683M92.7228 62.7548V38.7808C92.7228 34.0121 90.8284 29.4387 87.4564 26.0667C84.0844 22.6947 79.511 20.8003 74.7423 20.8003L50.7683 74.7418V140.67H118.375C121.266 140.703 124.071 139.69 126.274 137.817C128.477 135.945 129.929 133.34 130.362 130.481L138.633 76.5399C138.894 74.8219 138.778 73.0677 138.293 71.3989C137.809 69.7302 136.967 68.1867 135.827 66.8754C134.687 65.5642 133.275 64.5165 131.69 63.805C130.105 63.0935 128.384 62.7351 126.646 62.7548H92.7228Z"
            stroke="url(#paint0_linear_4068_12065)"
            strokeOpacity="0.45"
            strokeWidth="13.328"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4068_12065"
              x1="79.7848"
              y1="20.8003"
              x2="79.7848"
              y2="140.671"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2D6ADF" />
              <stop offset="1" stopColor="#E7EAF4" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
    {
      title: "300",
      content:
        language === "ko"
          ? [
              "SLASH의 첫 300명 Believer 중 한 명이 되어보세요",
              "멤버십 결제 순서에 따라 1~300의 고유 번호를 부여 받아요(내 Believer 번호는 프로필에서 확인할 수 있어요)",
            ]
          : [
              `Become one of SLASH’s first 300 Believers`,
              `Receive a unique number from 1 to 300 based on your membership order`,
            ],
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="160"
          height="160"
          viewBox="0 0 160 160"
          fill="none"
        >
          <path
            d="M40.0039 120C40.4283 120 40.8656 120 41.3166 120H80.0039M40.0039 120C33.4154 119.995 29.9595 119.912 27.2826 118.548C24.7737 117.27 22.7355 115.225 21.4572 112.716C20.0039 109.864 20.0039 106.135 20.0039 98.668V61.3346C20.0039 53.8673 20.0039 50.1308 21.4572 47.2786C22.7355 44.7698 24.7737 42.7316 27.2826 41.4532C30.1347 40 33.8712 40 41.3385 40H118.672C126.139 40 129.868 40 132.72 41.4532C135.229 42.7316 137.274 44.7698 138.552 47.2786C140.004 50.128 140.004 53.86 140.004 61.3127V98.6873C140.004 106.14 140.004 109.867 138.552 112.716C137.274 115.225 135.229 117.27 132.72 118.548C129.871 120 126.144 120 118.691 120H80.0039M40.0039 120C40.0042 112.636 48.9584 106.667 60.0039 106.667C71.0496 106.667 80.0039 112.636 80.0039 120M40.0039 120C40.0039 120 40.0039 120 40.0039 120ZM120.004 93.3333H93.3372M120.004 73.3333H100.004M60.0039 86.6667C52.6401 86.6667 46.6706 80.6971 46.6706 73.3333C46.6706 65.9695 52.6401 60 60.0039 60C67.3677 60 73.3372 65.9695 73.3372 73.3333C73.3372 80.6971 67.3677 86.6667 60.0039 86.6667Z"
            stroke="url(#paint0_linear_4068_12075)"
            strokeOpacity="0.45"
            strokeWidth="13.3333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4068_12075"
              x1="80.0039"
              y1="40"
              x2="80.0039"
              y2="120"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2D6ADF" />
              <stop offset="1" stopColor="#E7EAF4" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
    {
      title: "Personalized Onboarding",
      content:
        language === "ko"
          ? [
              "SLASH를 더욱 효과적으로 활용하고 싶다면 Believer 전용 1:1 온보딩을 신청해보세요",
            ]
          : [
              `For a more effective SLASH experience, apply for 1:1 onboarding exclusively for Believers (Your Believer number is available in your profile)`,
            ],
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="160"
          height="160"
          viewBox="0 0 160 160"
          fill="none"
        >
          <path
            d="M99.998 126.667C99.998 111.939 82.0894 100 59.998 100C37.9067 100 19.998 111.939 19.998 126.667M112.186 34.4792C114.662 36.9554 116.626 39.8951 117.966 43.1305C119.306 46.3658 119.997 49.8355 119.997 53.3375C119.997 56.8394 119.307 60.2998 117.967 63.5352C116.627 66.7705 114.662 69.7137 112.186 72.1899M126.665 20C131.042 24.3774 134.514 29.5741 136.883 35.2935C139.252 41.0128 140.477 47.1418 140.477 53.3323C140.477 59.5229 139.255 65.6544 136.886 71.3737C134.517 77.093 131.042 82.2903 126.665 86.6677M59.998 80C45.2705 80 33.3314 68.0609 33.3314 53.3333C33.3314 38.6057 45.2705 26.6667 59.998 26.6667C74.7256 26.6667 86.6647 38.6057 86.6647 53.3333C86.6647 68.0609 74.7256 80 59.998 80Z"
            stroke="url(#paint0_linear_4068_12082)"
            strokeOpacity="0.45"
            strokeWidth="13.3333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4068_12082"
              x1="80.2375"
              y1="20"
              x2="80.2375"
              y2="126.667"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2D6ADF" />
              <stop offset="1" stopColor="#E7EAF4" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
  ];

  return (
    <>
      {render && (
        <Container>
          <BelieverHeader>
            <Fade direction="up" triggerOnce={true} duration={1500}>
              <BelieverHeaderIcon>🚀 Believer</BelieverHeaderIcon>
              <BelieverHeaderTitle>
                <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                  Believe
                </span>
                &nbsp;in your future
              </BelieverHeaderTitle>
              <BelieverHeaderSubtitle>
                {language === "ko"
                  ? `자신의 가능성을 믿는 프로를 위한 플랜을 만나보세요`
                  : `Discover the plan for professionals who believe in their potential`}
              </BelieverHeaderSubtitle>
            </Fade>
          </BelieverHeader>
          <Fade direction="up" triggerOnce={true} duration={1500}>
            <BelieverContentTitle>
              {language === "ko"
                ? `Believer를 위한 혜택`
                : `Benefits for Believers`}
            </BelieverContentTitle>
          </Fade>
          <Fade direction="up" triggerOnce={true} duration={1500}>
            <BelieverContentWrapper>
              <BelieverContentCardWrapper style={{ marginRight: "32px" }}>
                {benefits.map((benefit, idx) =>
                  benefit.title === "300" ||
                  benefit.title === "Personalized Onboarding" ? null : (
                    <BelieverContentCard key={idx}>
                      <BelieverContentCardTitle>
                        {benefit.title}
                      </BelieverContentCardTitle>
                      <BelieverContentCardSubtitle>
                        {benefit.content}
                      </BelieverContentCardSubtitle>
                      <BelieverContentCardIcon>
                        {benefit.icon}
                      </BelieverContentCardIcon>
                    </BelieverContentCard>
                  )
                )}
              </BelieverContentCardWrapper>
              <BelieverContentCardWrapper>
                {benefits.map((benefit, idx) =>
                  benefit.title === "Invest in your Growth" ||
                  benefit.title === "Early Access" ||
                  benefit.title === "Contiributions" ? null : (
                    <BelieverContentCard key={idx}>
                      <BelieverContentCardTitle>
                        {benefit.title}
                      </BelieverContentCardTitle>
                      {benefit.content.map((content, idx) => (
                        <BelieverContentCardSubtitle key={idx}>
                          {content}
                        </BelieverContentCardSubtitle>
                      ))}
                      <BelieverContentCardIcon>
                        {benefit.icon}
                      </BelieverContentCardIcon>
                    </BelieverContentCard>
                  )
                )}
              </BelieverContentCardWrapper>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: isMobile ? `#88f4fa` : `#b7fbff`,
                  filter: isMobile ? "blur(150px)" : "blur(200px)",
                  transform: "translate3d(0, 0, 0)",
                  borderRadius: "600px",
                  left: isMobile ? "50%" : "80%",
                  bottom: isMobile ? "34%" : "0%",
                  position: "absolute",
                  zIndex: "-1",
                }}
              />
              <div
                style={{
                  width: "100%",
                  height: "80%",
                  backgroundColor: isMobile ? `#CD6CFC` : `#f3dbff`,
                  filter: isMobile ? "blur(200px)" : "blur(250px)",
                  transform: "translate3d(0, 0, 0)",
                  borderRadius: "600px",
                  left: isMobile ? "78%" : "65%",
                  bottom: isMobile ? "-150%" : "0%",
                  position: "absolute",
                  zIndex: "-1",
                }}
              />
              <div
                style={{
                  width: "80%",
                  height: "80%",
                  backgroundColor: "#d7e6ff",
                  filter: isMobile ? "blur(60px)" : "blur(250px)",
                  transform: "translate3d(0, 0, 0)",
                  borderRadius: "1600px",
                  left: isMobile ? "-45%" : "-40%",
                  position: "absolute",
                  zIndex: "-2",
                }}
              />
            </BelieverContentWrapper>
          </Fade>
          <Fade
            direction="up"
            triggerOnce={true}
            duration={1500}
            style={{ width: "100%" }}
          >
            <PricingPhilosophy isMobile={isMobile} language={language} />
          </Fade>
          <Fade
            direction="up"
            triggerOnce={true}
            duration={1500}
            style={{ width: "100%" }}
          >
            <FooterStartButton
              isTablet={isTablet}
              isSmallTablet={isSmallTablet}
              isMobile={isMobile}
              language={language}
            />
          </Fade>
        </Container>
      )}
    </>
  );
};

export default Believer;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 175px;
`;

const BelieverHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
`;

const BelieverHeaderIcon = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.sub3};
  border-radius: 164.948px;
  color: ${COLORS.brand1};
  font-size: 21.443px;
  font-weight: 700;
  line-height: 32.99px;
  letter-spacing: -0.412px;
  padding: 3.299px 13.196px;
`;

const BelieverHeaderTitle = styled.div`
  font-size: 54px;
  font-weight: 700;
  line-height: 150%; /* 81px */
  letter-spacing: -0.25px;
  margin: 40px 0px 12px 0px;
`;

const BelieverHeaderSubtitle = styled.div`
  color: ${COLORS.gray500};
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%; /* 30px */
  white-space: pre-line;
`;

const BelieverContentTitle = styled.div`
  text-align: center;
  font-size: 48px;
  font-weight: 500;
  line-height: 36px; /* 75% */
  letter-spacing: -0.25px;
`;

const BelieverContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;
  position: relative;
  margin-bottom: 240px;
`;

const BelieverContentCardWrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
`;

const BelieverContentCard = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid var(--Gray_100, #f2f5fc);
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(45, 106, 223, 0.08);
  :not(:first-child) {
    margin-top: 24px;
  }
`;

const BelieverContentCardTitle = styled.div`
  font-size: 34px;
  font-weight: 700;
  line-height: 46px; /* 135.294% */
  letter-spacing: -0.25px;
`;

const BelieverContentCardSubtitle = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 36px; /* 150% */
  letter-spacing: -0.25px;
  margin-top: 8px;
`;

const BelieverContentCardIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;
